import React, { useEffect, useState } from "react";
import "./Log.css"; // Assuming your CSS is in a separate Login.css file
import axios from "axios";
import { toast } from "react-toastify";

function Log() {
	// State to handle form input
	const [saId, setSaId] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(true); // Loading state

	useEffect(() => {
		if (localStorage.getItem("nssc-sa-token")) {
			window.location.replace("/details");
		} else {
			setLoading(false); // Set loading to false if no token is found
		}
	}, []);

	// Handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();
		if (!saId || !password) {
			toast.error("Please fill all the fields");
			return;
		}
		axios
			.post(`${process.env.REACT_APP_API_URL}/ambassador/login`, {
				saID: saId,
				password: password,
			})
			.then((res) => {
				if (res.data.status === 200) {
					toast.success(res.data.message);
					localStorage.setItem("nssc-sa-token", res.data.token);
					return window.location.replace("/details");
				} else {
					toast.error(res.data.message);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	if (loading) {
		return <div>Loading...</div>; // Show loading state
	}

	return (
		<div className="login_container">
			<div className="login_card">
				<h2>SA Login</h2>
				<form onSubmit={handleSubmit}>
					{/* SA-ID Input */}
					<input
						type="text"
						id="sa-id"
						name="sa-id"
						placeholder="SA-ID"
						value={saId}
						onChange={(e) => setSaId(e.target.value)}
						required
					/>

					{/* Password Input */}
					<input
						type="password"
						id="password"
						name="password"
						placeholder="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
					/>

					<button className="login_buttonsa" type="submit">
						Submit
					</button>
				</form>
			</div>
		</div>
	);
}

export default Log;
