// Modal.js
import React from "react";
import "./Modal.css"; // Import styling for the modal
import glData from "../data/gl.json"; // Import the GL data
import eventsData from "../data/events.json"; // Import the events data

const Modal = ({ student, onClose }) => {
	if (!student) return null;
	let eventCount = 0;
	let glCount = 0;

	return (
		<div className="modal-overlay">
			<div className="modal-content-change">
				<button className="modal-close" onClick={onClose}>
					&times;
				</button>
				<h2>{student.fName}'s Registration Details</h2>
				<p>
					<strong>GL Registered:</strong>{" "}
				</p>
				<ul>
					{Object.keys(glData).map((key, index) => {
						if (student.gl[key]) {
							glCount++;
							return <li key={index}>{glData[key]}</li>;
						}
					})}
					{glCount === 0 ? <p>Haven't Registered for any GL</p> : null}
				</ul>

				<p>
					<strong>Events Registered:</strong>
				</p>
				<ul>
					{Object.keys(eventsData).map((key, index) => {
						let event =
							key <= 3 ? student.events[key].status : student.events[key];
						if (event) {
							eventCount++;
							return <p key={index}>{eventsData[key].name}</p>;
						}
					})}
					{eventCount === 0 ? <p>Haven't Registered for any events</p> : null}
				</ul>
			</div>
		</div>
	);
};

export default Modal;
