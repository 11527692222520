import React from "react";
// import NavigationBar from './components/Navbar';
import Carousel from "./components/Carousal";
import AboutSection from "./components/About";
import FactSection from "./components/Fact";
import ClientsSection from "./components/Swipe";
import FormExample from "./components/Form";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Log from "./components/Log";
import Detail from "./components/Detail";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import NavigationBar from './components/Navbar';
const NotFound = () => {
	return (
		<div
			style={{
				height: "100vh",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<h1>404</h1>
			<p>Page not found</p>
			<Link to="/">Return Home</Link>
		</div>
	);
};

function App() {
	return (
		<Router>
			<div className="App">
				<ToastContainer />
				{/* Define Routes */}
				<Routes>
					{/* Default route for the main page */}
					<Route
						path="/"
						element={
							<>
								<Carousel />
								<AboutSection />
								<FactSection />
								<ClientsSection />
								<FormExample />
							</>
						}
					/>

					{/* Route for /temp that displays Temp component */}
					<Route path="/login" element={<Log />} />
					<Route path="/details" element={<Detail />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</div>
		</Router>
	);
}

export default App;
